export const BACKGROUND = 'background'
export const PLATE = 'plate'
export const WHITEMASK = 'whitemask'
export const TOAST_UNLOCKED = 'toastunlocked'
export const TOAST_UNLOCKED_ANIM = 'toastUnlockedAnim'
export const TOAST_UNLOCKED_BOUNCE = 'bounceToastUnlocked'

export const ARROW = 'arrow'
export const ANGLE_GAUGE = 'angleGauge'
export const ANGLE_POINTER = 'anglePointer'
export const SPEED_BG = 'speedbg'
export const SPEED_BG_ANIM = 'speedAnim'
export const STRAW = 'straw'
export const COLA = 'cola'
export const PLUS = 'plus'
export const MINUS = 'minus'

export const LOST_SFX = 'lostSFX'
export const WIN_SFX = 'winSFX'
export const SPLAT_SFX = 'splatSFX'
export const WOOSH_SFX = 'wooshSFX'
export const SLIP_SFX = 'slipSFX'
export const CAT_SFX = 'catSFX'
export const GLASS_SFX = 'glassSFX'
export const IDLE_BGM = 'idleBGM'
export const SPIN_BGM = 'spinBGM'

export const WIN_1 = 'balloonwin1'
export const WIN_1_ANIM = 'win1Anim'
export const WIN_1_POPUP = 'popupballoonwin1'
export const WIN_2 = 'balloonwin2'
export const WIN_2_ANIM = 'win2Anim'
export const WIN_2_POPUP = 'popupballoonwin2'
export const WIN_3 = 'balloonwin3'
export const WIN_3_ANIM = 'win3Anim'
export const WIN_3_POPUP = 'popupballoonwin3'
export const FAIL_1 = 'balloonfail1'
export const FAIL_1_ANIM = 'fail1Anim'
export const FAIL_1_POPUP = 'popupballoonfail1'
export const FAIL_2 = 'balloonfail2'
export const FAIL_2_ANIM = 'fail2Anim'
export const FAIL_2_POPUP = 'popupballoonfail2'
export const SPLAT_1 = 'balloonsplat1'
export const SPLAT_1_ANIM = 'splat1Anim'
export const SPLAT_1_POPUP = 'popupballoonsplat1'
export const WTF_1 = 'balloonwtf1'
export const WTF_1_ANIM = 'wtf1Anim'
export const WTF_1_POPUP = 'popupballoonwtf1'

export const SPLASH_SCREEN = 'splashScreen'

export const TOAST_EGG = 'toastegg'
export const TOAST_EGG_ANIM = 'toastEggAnim'
export const TOAST_EGG_SPIN = 'spinToastEgg'
export const TOAST_JAM = 'toastjam'
export const TOAST_JAM_ANIM = 'toastJamAnim'
export const TOAST_JAM_SPIN = 'spinToastJam'

export const TOAST_BUTTER = 'toastbutter'
export const TOAST_BUTTER_ANIM = 'toastButterAnim'
export const TOAST_BUTTER_SPIN = 'spintoastbutter'
export const TOAST_CROQ = 'toastcroq'
export const TOAST_CROQ_ANIM = 'toastCroqAnim'
export const TOAST_CROQ_SPIN = 'spintoastcroq'
export const TOAST_NUTELLA = 'toastnutella'
export const TOAST_NUTELLA_ANIM = 'toastNutellaAnim'
export const TOAST_NUTELLA_SPIN = 'spintoastnutella'
export const TOAST_PIZZA = 'toastpizza'
export const TOAST_PIZZA_ANIM = 'toastPizzaAnim'
export const TOAST_PIZZA_SPIN = 'spintoastpizza'
export const TOAST_CAT = 'toastcat'
export const TOAST_CAT_ANIM = 'toastCatAnim'
export const TOAST_CAT_SPIN = 'spintoastcat'